<template>
  <div>
    <Header :customstyle="{ background: '#0D1912' }">
      <template slot="title">环保快讯</template>
    </Header>
    <div class="announce">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in listdata" :key="item.id">
            <div class="cell" @click="clickDetail(item.id)">
              <div class="describle van-ellipsis">
                {{ item.title }}
              </div>
              <div class="time">
                {{ item.ctime }}
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { getNoticeList } from "@/request/api";
export default {
  components: { Header },
  data() {
    return {
      lang: 1,
      refreshing: false,
      loading: false,
      finished: false,
      currentPage: 1,
      listdata: [],
    };
  },
  created() {},
  methods: {
    clickDetail(detail) {
      this.$router.push({
        path: "/home/index/announce/detail",
        query: {
          id: detail,
        },
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      this.getDataList();
      this.currentPage++;
    },
    getDataList() {
      getNoticeList({
        cate: this.lang,
        limit: "20",
        page: this.currentPage,
      }).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
          this.listdata = [];
        }
        this.listdata = [...this.listdata, ...res.data.list];
        this.loading = false;
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.announce {
  background: #0c1913;
  min-height: 100vh;
  padding: 10px 32px;
  .cell {
    width: 686px;
    /*height: 144px;*/
    border-radius: 24px;
    opacity: 1;
    background: rgba(32, 40, 35, 1);
    margin-bottom: 24px;
    padding: 20px 0;
    .describle {
      padding: 0px 24px;
      opacity: 1;
      color: rgba(214, 255, 227, 1);
      p {
        font-size: 32px;
        font-weight: 400;
        font-family: "PingFang SC";
      }
    }
    .time {
      padding: 0px 24px;
      color: rgba(139, 166, 149, 1);
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: right;
      text-align: left;
    }
  }
}
</style>